import React, { useEffect } from "react";
import Helmet from "react-helmet";
import HomepageLayout from "src/layouts/homepage";
import JSONData from "branding/site-metadata.json";
import RawFooter from "@tightrope/footerlinks/rawfooter.js"
import "./pp.css"; // Import CSS file here

export default function Privacy() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <HomepageLayout>
            <Helmet data={JSONData}><title>Privacy Policy</title></Helmet>
            <header style={{ background: "#0f26b0", width: "100%", padding: "20px", textAlign: "center" }}>
                <img src="/img/mainlogo.png" alt="Main Logo" />
            </header>
            <div
                name="termly-embed"
                data-id="f74dab8f-2de7-4114-bd7d-25ecf7836712"
                data-type="iframe"
            ></div>
            <RawFooter />
        </HomepageLayout>
    );
}